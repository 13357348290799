/* eslint-disable @next/next/no-img-element */
import { Box, Typography, Stack, useTheme, Button } from '@mui/material';
import Link from 'next/link';
import { useAppSettingsContext } from '@/app/components/contexts/app-settings-context';
import InViewAnimationComponent from '@/app/components/in-view-animation-container';

const storeData = [
  {
    image: '/assets/apps/store/google_play.png',
    link: 'https://play.google.com/store/apps/details?id=flix.pro.tv',
    alt: 'android',
  },
  {
    image: '/assets/apps/store/samsung.png',
    link: 'https://www.samsung.com/us/tvs/smart-tv/samsung-tv-plus-and-smart-hub/',
    alt: 'samsung',
  },
  {
    image: '/assets/apps/store/roku.png',
    link: 'https://channelstore.roku.com/details/3cd1494c8fd08c19752f8bde02d1aee6/flix-pro-player-iptv',
    alt: 'roku',
  },
  {
    image: '/assets/apps/store/ios.png',
    link: 'https://apps.apple.com/us/app/flix-pro-player/id6736839397',
    alt: 'apple',
  },
  {
    image: '/assets/apps/store/lg.png',
    link: 'https://us.lgappstv.com/main/tvapp/detail?appId=1248437',
    alt: 'lg',
  },
  {
    image: '/assets/apps/store/windows.png',
    link: 'https://www.microsoft.com/store/apps/9NTQJJS1DXTC',
    alt: 'windows',
  },
  // {
  //   image: '/assets/apps/store/web.png',
  //   link: '/coming-soon',
  //   alt: 'web',
  // },
];

export default function DownloadApps() {
  const theme = useTheme();

  const { settings } = useAppSettingsContext();
  const androidSetting = Array.isArray(settings)
    ? settings.find(item => item.key === 'android-setting')
    : undefined;

  const windowsSetting = Array.isArray(settings)
    ? settings.find(item => item.key === 'windows-setting')
    : undefined;

  return (
    <Box
      sx={{
        mx: 'auto',
        width: {
          md: '100%',
          lg: '1200px',
        },
        pt: 15,
        pb: 15,
      }}
      id="download-apps"
    >
      <Stack gap={6}>
        <Stack alignItems="center" gap={2} paddingX={3}>
          <Typography variant="h3">Download Flix Pro Player</Typography>
          <Typography
            variant="h6"
            sx={{
              maxWidth: 600,
              textAlign: 'center',
            }}
            color={theme.palette.text.secondary}
          >
            Experience seamless streaming with Flix Pro Player—your ultimate destination for
            downloading and enjoying high-quality videos
          </Typography>
        </Stack>
        <Stack paddingX={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexFlow: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {storeData.map((item, index) => (
              <Link key={index} href={item.link} target="_blank">
                <InViewAnimationComponent>
                  <Box
                    sx={{
                      height: 60,
                      '& img': {
                        height: '60px',
                        objectFit: 'contain',
                        borderRadius: '8px',
                      },
                    }}
                  >
                    <img src={item.image} alt={item.alt} />
                  </Box>
                </InViewAnimationComponent>
              </Link>
            ))}
          </Box>
        </Stack>

        <Stack paddingX={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 3,
            }}
          >
            {androidSetting &&
              androidSetting?.value &&
              androidSetting.value?.path &&
              androidSetting.value?.code && (
                <InViewAnimationComponent>
                  <Stack gap={1}>
                    <Typography variant="subtitle1" color={theme.palette.text.secondary}>
                      {`Download the apk file for Android, Fire TV (AFT downloader code is ${androidSetting.value?.code}) :`}
                    </Typography>
                    <Button
                      onClick={() => {
                        const uniqueSuffix = new Date().getTime();

                        window.location.href = `${androidSetting.value?.path}?v=${uniqueSuffix}`;
                      }}
                    >
                      <Box
                        sx={{
                          height: 60,
                          '& img': {
                            height: '60px',
                            objectFit: 'contain',
                            borderRadius: '8px',
                            width: '180px',
                          },
                        }}
                      >
                        <img src="/assets/apps/direct/android.png" alt="" />
                      </Box>
                    </Button>
                  </Stack>
                </InViewAnimationComponent>
              )}

            {windowsSetting && windowsSetting?.value && windowsSetting.value?.path && (
              <InViewAnimationComponent>
                <Stack gap={1}>
                  <Typography variant="subtitle1" color={theme.palette.text.secondary}>
                    Download exe file for Windows Desktop :
                  </Typography>
                  <Button
                    onClick={() => {
                      const uniqueSuffix = new Date().getTime();

                      window.location.href = `${windowsSetting.value?.path}?v=${uniqueSuffix}`;
                    }}
                  >
                    <Box
                      sx={{
                        height: 60,
                        '& img': {
                          height: '60px',
                          objectFit: 'contain',
                          borderRadius: '8px',
                        },
                      }}
                    >
                      <img src="/assets/apps/direct/exe.png" alt="" />
                    </Box>
                  </Button>
                </Stack>
              </InViewAnimationComponent>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
