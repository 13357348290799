'use client';

import Box from '@mui/material/Box';
import { MainHeader } from '../header/main-header';
import Footer from '../footer';
// import { BackToTop } from '../back-to-top';

export default function MasterLayout({ children }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <MainHeader />

      {/* <USPList /> */}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        {children}
      </Box>

      <Footer />
      {/* <BackToTop /> */}
    </Box>
  );
}
